@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.App,
main {
    height: 100%;
}

/* ::-webkit-scrollbar {
    width: 0.4em;
    height: 0.4em;
    background-color: rgba(255, 255, 255, 0);
}
::-webkit-scrollbar-thumb {
    background-color: rgba(255,255,255, 0.5);
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255,255,255, 0.7);
} */

/* class to force text color to white except path */
.force-white *:not(path) {
    color: white !important;
}